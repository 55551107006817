export function _domainTransferError(errorType: string) {
  const table: { [key: string]: string } = {
    ['ApiInvalidParameterError']: 'パラメータエラー',
    ['ApiEntityReferenceNotFound']:
      'パラメーターで指定した公開連絡窓口番号、登録者番号やネームサー バーホストが、当システムに登録されていないか、gTLD 取次サービス で必須の項目（住所英語など）が登録されていません。また、指定し たネームサーバーホストの親ドメイン名が登録されていない場合もこ のエラーを返します。',
    ['ApiDoesNotAllowOperation']:
      'ドメインの廃止処理中、紛争中、移管中、もしくはレジストラロック中のためドメイン移管できません。',
    ['ApiRegistrantTransferProcessing']:
      'ドメインの廃止処理中、紛争中、移管中、もしくはレジストラロック中のためドメイン移管できません。',
    ['ApiDomainLock']: 'ロックされているためドメイン移管を申請できません。',
    ['ApiRegistrantStatusDoesNotAllowForOperation']:
      'ドメインの廃止処理中、紛争中、移管中、もしくはレジストラロック中のためドメイン移管できません。',
    ['ApiRegistrantHoldingDRPdomain']:
      '現在紛争中のドメインであるためドメイン移管できません。',
    ['ApiAgentAlreadyHoldThisRegistrant']: '既に移管済みのドメインです。',
    ['ApiRegistrantClassDoesNotAllowForOperation']:
      '取り扱いに制限があるドメインであるためドメイン移管できません',
    ['ApiRegistrantIsSourceOfDomainTransfer']:
      'ドメイン移管保留中のものがあるためドメイン移管できません。',
    ['ApiRegistrantIsDestinationOfDomainTransfer']:
      '保留中のドメイン名移転の移転先として指定されている登録者は削除できません。また、指定事業者変更も行うことはできません。',
    ['ApiOperationNotAllowedRegistrarLock']:
      'レジストラロックが設定されているため、申請は受け付けられません。',
    ['ApiRegistrantIsDestinationOfRegistarTransfer']:
      '登録者情報はレジストラトランスファー仕掛り中のため、申請を受け付けられません。',
    ['ApiContactIsDestinationOfRegistrarTransfer']:
      '公開連絡窓口情報はレジストラトランスファー仕掛り中のため、申請を受け付けられません。',
    ['ApiContactIsDestinationOfDomainTransfer']:
      'ドメイン名移転中につき公開連絡窓口を操作できません。',
    ['ApiPrefectureTypeJPDomainNameDoesNotAllowForOperation']:
      '都道府県型 JP ドメイン名の取次特則に同意していないため、都道府県型 JP ドメイン名に関する申請が受け付けられません。',
    ['ApiRegistrantIsSourceOfRegistrarTransfer']:
      '保持するドメイン名の中にレジストラトランスファーアウト仕掛り中のものがあるため、要求された申請が実行できません。',
  }

  if (errorType in table) {
    return table[errorType]
  }

  return 'ドメイン移管の申請に失敗しました。'
}

export const getErrorMessage = (subStatusCode: number, details: any) => {
  console.log(556666, subStatusCode, details)
  switch (subStatusCode) {
    case 1:
      return `Apiのパラメータが異なります。${details}`
    case 2:
      return `顧客情報が見つかりません。${details}`
    case 3:
      return `リソースが見つかりません。${details}`
    case 4:
      return `既に${details}の本登録の申し込みは受けております。`
    case 5:
      return `既にそのユーザーは登録されています。${details}`
    case 6:
      return `指定されたサービスが見つかりません。${details}`
    case 7:
      return `Stripeのセッション開始に失敗しました。暫くしてから再度お申込み下さい。${details}`
    case 13:
      return '現在お支払いは必要ありません。'
    case 15:
      return '請求書がありません。'
    case 16:
      return 'サービスがあるため削除できません。'
    case 17:
      return 'クレジットカードが登録されていません。'
    case 24:
      return details.msg
    case 25:
      return '失敗しました。カード会社にご確認お願いします。'
    case 26:
      return '不明なエラー。お手数ですが、暫くたってから再度行ってください。'
    case 29:
      return _domainTransferError(details)
    default:
      return 'Unkown Error'
  }
}
