import { http } from '@/plugins/ky'

type GetResponse = sessionInfo

export type sessionInfo = {
  userName: string
  idToken: string
  accessToken: string
  refreshToken: string
  userPoolId: string
  userPoolWebClientId: string
}

const get = (sessionId: string): Promise<GetResponse> => {
  return http
    .get('token', {
      prefixUrl: process.env.VUE_APP_ACCOUNT_API_URL,
      searchParams: {
        sessionId,
      },
    })
    .json<GetResponse>()
}

export { get, GetResponse }
