import { createApp } from 'vue'
import { router } from '@/router'
import '@/registerServiceWorker'
import '@/index.scss'

import App from '@/App.vue'
import store from '@/store'
import sentry from '@/plugins/sentry'
import http from '@/plugins/ky'
import amplify from '@/plugins/amplify'
import i18n from '@/plugins/i18n'
import VueLazyLoad from 'vue3-lazyload'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faThLarge,
  faBars,
  faUserCircle,
  faTimes,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//<i class="fas fa-sign-out-alt"></i>
library.add(faThLarge)
library.add(faBars)
library.add(faUserCircle)
library.add(faTimes)
library.add(faSignOutAlt)

createApp(App)
  .use(store)
  .use(i18n)
  .use(router)
  .use(VueLazyLoad)
  .use(sentry)
  .use(amplify)
  .use(http)
  .component('fa-icon', FontAwesomeIcon)
  .mount('#app')
