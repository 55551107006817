import i18n from '@/plugins/i18n'

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import { Hub } from '@aws-amplify/core'
import { authGuard } from '@/router/authenticator'
import { sessionResolver } from '@/router/session-resolver'
import routes from '@/router/routes'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(sessionResolver)

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.params.locale

  if (!language) {
    language = 'ja'
  }

  i18n.global.locale.value = language.toString()
  const splitted = to.fullPath.split('/')

  const paths = ['/thanks', '/failed', '/signout']

  if (
    !paths.includes(to.fullPath) &&
    splitted.length >= 2 &&
    splitted[1] !== i18n.global.locale.value
  ) {
    const { fullPath, query } = to
    next({ path: `/${i18n.global.locale.value}${fullPath}`, query })
  } else {
    next()
  }
})

router.beforeResolve(authGuard)

// Hub.listen('auth', async (data) => {
//   switch (data.payload.event) {
//     case 'signIn':
//       break
//     case 'signOut':
//       break
//   }
// })

export { router }
