import { http } from '@/plugins/ky'
import { errorHandler } from '@/api/utils'

const get = (signOutId: string): Promise<boolean> =>
  http
    .get('signout', {
      prefixUrl: process.env.VUE_APP_ACCOUNT_API_URL,
      searchParams: {
        signOutId,
      },
    })
    .json<boolean>()

export const post = () =>
  new Promise<{ signOutId: string }>((resolve, reject) =>
    http
      .post('signout', {
        prefixUrl: process.env.VUE_APP_ACCOUNT_API_URL,
      })
      .then(async (r: any) => {
        const result = await r.json()
        resolve({ ...result })
      })
      .catch(async ({ response, message }: any) => {
        await errorHandler(response, message)
        reject()
      })
  )

export { get }
