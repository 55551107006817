import { NavigationGuardWithThis } from 'vue-router'
import { auth, signOut } from '@/auth'

const sessionResolver: NavigationGuardWithThis<undefined> = async (
  { query, path },
  _,
  next
) => {
  if (typeof query.signOutId === 'string' && path.endsWith('/signout')) {
    const services =
      typeof query.serviceList !== 'string'
        ? []
        : decodeURIComponent(query.serviceList).split(',')

    await signOut(services, query.signOutId)

    return
  }

  if (
    typeof query.sessionId === 'string' ||
    typeof query.sessionId === 'undefined'
  ) {
    await auth(query.sessionId)

    if (typeof query.sessionId === 'undefined') {
      next()
    } else {
      next({
        path,
        query: {
          ...query,
          sessionId: undefined,
        },
      })
    }
  }
}

export { sessionResolver }
