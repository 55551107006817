import { createI18n } from 'vue-i18n'
import { en } from '@/locales/en'
import { ja } from '@/locales/ja'

const i18n = createI18n({
  locale: window.navigator.language,
  legacy: false,
  fallbackLocale: 'en',
  formatFallbackMessages: true,
  messages: {
    en,
    ja,
  },
})

export default i18n
