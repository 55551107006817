import { NavigationGuardWithThis } from 'vue-router'
import urlJoin from 'url-join'
import { CERTIFICATE_DOMAIN } from '@/constants'
import { getRedirectUrl } from '@/functions/pure/utils'
import { redirectToSignIn } from '@/auth'
import { refreshToken } from '@/auth/token'

const authGuard: NavigationGuardWithThis<undefined> = async (
  { meta, fullPath },
  _,
  next
) => {
  if (!meta.requiresAuth) {
    next()
    return
  }

  const token = await refreshToken()

  if (token != null) {
    next()
  } else {
    redirectToSignIn()
  }
}

export { authGuard }
