import Amplify, { Auth } from 'aws-amplify'
import {
  CognitoUserSession,
  ICognitoUserSessionData,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
  CognitoUserPool,
  CognitoUser,
} from 'amazon-cognito-identity-js'
import urlJoin from 'url-join'
import { refreshToken } from '@/auth/token'
import { get, post } from '@/api/signout'
import { get as getSessionInfo, sessionInfo } from '@/api/token'

/* eslint-disable @typescript-eslint/camelcase */
const config = {
  aws_cognito_region: process.env.VUE_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.VUE_APP_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.VUE_APP_AWS_USER_POOL_APP_CLIENT_ID,
}

Amplify.configure(config)

export function removeSessionId(url: string) {
  // urlの文字に何が使用されているのかちょっと自信がないので慎重にremoveする。
  const splitted = url.split('?')

  if (splitted.length === 1) return url

  if (splitted.length > 2) {
    throw Error(`dnsauth removeSessionId: can not handle ${url}`)
  }

  //TODO: 正規表現がよう分からんのでとりあえずこれでいくがもっとよい方法探す。
  const removed = splitted[1]
    .split('&')
    .filter((q) => !q.startsWith('sessionId'))
    .join('&')

  if (removed.length === 0) {
    return splitted[0]
  }

  return `${splitted[0]}?${removed}`
}

export function redirectToSignIn() {
  location.replace(
    urlJoin(
      process.env.VUE_APP_ROOT_AUTH_DOMAIN!,
      `?redirect=${encodeURIComponent(removeSessionId(location.href))}`
    )
  )
}

export const _getJwt = (sessionId: string): Promise<sessionInfo> =>
  getSessionInfo(sessionId)

function _saveSessionToLocal(session: sessionInfo) {
  const Pool = new CognitoUserPool({
    UserPoolId: session.userPoolId,
    ClientId: session.userPoolWebClientId,
  })

  const cognitoUser = new CognitoUser({
    Username: session.userName,
    Pool,
  })

  const sessionData: ICognitoUserSessionData = {
    IdToken: new CognitoIdToken({ IdToken: session.idToken }),
    AccessToken: new CognitoAccessToken({ AccessToken: session.accessToken }),
    RefreshToken: new CognitoRefreshToken({
      RefreshToken: session.refreshToken,
    }),
  }

  const userSession = new CognitoUserSession(sessionData)

  cognitoUser.setSignInUserSession(userSession)
}

export async function auth(sessionId?: string | null) {
  if (sessionId == null) {
    const token = await refreshToken()
    if (token == null) {
      redirectToSignIn()
    }
  } else {
    const session = await getSessionInfo(sessionId).catch(() => undefined)

    if (session == null) {
      redirectToSignIn()
    } else {
      _saveSessionToLocal(session)
      const token = await refreshToken()
      if (token == null) {
        redirectToSignIn()
      }
    }
  }
}

export async function startSingleSignOut() {
  const res = await post().catch(() => undefined)

  if (res == null) {
    location.replace('/failed')
    return
  }

  location.replace(
    `${process.env.VUE_APP_ROOT_AUTH_DOMAIN!}/signout?signOutId=${
      res.signOutId
    }`
  )
}

export async function signOut(services: string[], signOutId?: string) {
  const token = await refreshToken()

  if (signOutId === undefined) {
    location.replace('/failed')
    return
  }

  if (token != null) {
    if (!(await get(signOutId).catch(() => false))) {
      location.replace('/failed')
      return
    }

    await Auth.signOut().catch(() => undefined)
    localStorage.clear()
  }

  if (services.length === 0) {
    // All done
    location.replace(process.env.VUE_APP_ROOT_AUTH_DOMAIN!)
    return
  }

  const nextService = services.shift()

  const queryParameter =
    services.length > 0
      ? `?serviceList=${encodeURIComponent(
          services.join(',')
        )}&signOutId=${signOutId}`
      : `?signOutId=${signOutId}`

  location.replace(urlJoin(`https://${nextService}/signout`, queryParameter))
}
