import * as Sentry from '@sentry/browser'

import { useStore } from '@/store'
import { ActionTypes } from '@/store/actions'
import { getErrorMessage } from '@/functions/pure/error-handler'
import { loadStripe } from '@stripe/stripe-js'
import { Invoice } from '@/types'

export const comma = (num: number) => {
  return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
}

export const getMonth = (d: any) => ('0' + (d.getUTCMonth() + 1)).slice(-2)
export const getDay = (d: any) => ('0' + d.getUTCDate()).slice(-2)

export const formatDate = (date: any) => {
  const d = new Date(date)
  const str =
    d.getUTCFullYear() +
    '/' +
    getMonth(d) +
    '/' +
    getDay(d) +
    ' ' +
    ('0' + d.getUTCHours()).slice(-2) +
    ':' +
    ('0' + d.getUTCMinutes()).slice(-2) +
    ':' +
    ('0' + d.getUTCSeconds()).slice(-2)

  return str
}

export const errorHandler = async (response: any, message: any) => {
  const { dispatch } = useStore()
  if (response == null) {
    dispatch(ActionTypes.SetNotice, {
      message,
    })
    return
  }
  const { status } = response

  if (status === 422) {
    const { subStatusCode, detail } = await response.json()
    if (subStatusCode === 15 || subStatusCode === 17 || subStatusCode === 21) {
      // Do not anything when no billing.
      return
    }
    // console.log(subStatusCode, detail)
    dispatch(ActionTypes.SetNotice, {
      message: getErrorMessage(subStatusCode, detail), // TODO: リファクタリング：SetNotice側でエラーハンドリンクすべき
    })
    Sentry.captureMessage('Something went wrong')
  } else {
    dispatch(ActionTypes.SetNotice, {
      message,
    })
  }
}

export const createInvoice = (result: any): Invoice => {
  const paymentLimitDate = new Date(result.paymentLimitDate)
  const pyamentLimitMonth = getMonth(paymentLimitDate)
  const pyamentLimitDay = getDay(paymentLimitDate)

  return {
    date: new Date(result.date),
    createdAt: new Date(result.createdAt),
    paymentLimitDate: `${paymentLimitDate.getUTCFullYear()}/${pyamentLimitMonth}/${pyamentLimitDay}`,
    completed: result.paymentStatus,
    customerName: result.customerName,
    customerAddress: `${result.prefecture}${result.city}${result.address1}${result.address2}`,
    total: result.total,
    salesTax: result.salesTax,
    totalWithTax: result.totalWithTax,
    currency: result.total.currency,
    details: result.details,
  } as Invoice
}

export const stripeRedirect = async (sessionId: string) => {
  const STRIPE_PUBLISHABLE_KEY = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY!

  const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY)

  const result = await stripe?.redirectToCheckout({ sessionId })

  if (result != null && result.error) {
    const { dispatch } = useStore()
    dispatch(ActionTypes.SetNotice, {
      message: result.error.message,
    })
    const message =
      result.error.message == null
        ? 'Unkown error stripe redirect checkout'
        : result.error.message
    Sentry.captureMessage(message)
  }
}
