<template>
  <div class="bg-gray-100 min-h-full">
    <router-view />
  </div>

  <base-dialog
    to="body"
    class-dialog="backdrop-blur-2 bg-black bg-opacity-70 z-50"
    :model-value="isRetrying || inProgress"
  >
    <template #card>
      <div class="text-center text-white">
        <mdi-loading class="animate-spin w-16 h-16 sm:w-32 sm:h-32" />

        <p>{{ t('This might take a few minutes.') }}</p>
      </div>
    </template>
  </base-dialog>
  <transition name="fade">
    <div v-if="isOpen">
      <div
        class="fixed bottom-6 gap-4 max-w-xs flex hover:shadow-md transition-shadow duration-200 items-center left-6 rounded-full shadow bg-gray-100 py-2 px-3"
      >
        <component :is="icon" :class="iconColor" />
        <span class="flex-0 text-sm">
          {{ message }}
        </span>

        <!-- <button-close
          class="rounded-full w-6 h-6 bg-white p-1 shadow flex items-center"
          @click="resetNotice"
        /> -->
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { useStore } from '@/store'
import { computed, defineComponent, defineAsyncComponent } from 'vue'
import { ActionTypes } from '@/store/actions'
import { getStatusColor, getStatusIcon } from '@/functions/pure/utils'
const { dispatch, state } = useStore()
import { useTranslation } from '@/locales'

export default defineComponent({
  components: {
    BaseDialog: defineAsyncComponent(
      () => import('@/components/base/BaseDialog.vue')
    ),
    MdiLoading: defineAsyncComponent(
      () => import('@/components/base/icons/mdi/MdiLoading.vue')
    ),
  },

  setup() {
    const { t } = useTranslation()

    return {
      t,
      isOpen: computed(() => state.isOpen),
      message: computed(() => state.message),
      icon: computed(() => getStatusIcon(state.status)),
      iconColor: computed(() => getStatusColor(state.status)),
      isRetrying: computed(() => state.isRetrying),
      inProgress: computed(() => state.inProgress),
      resetNotice: () => dispatch(ActionTypes.ResetNotice),
    }
  },
})
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

body {
  height: 100%;
}

html {
  height: 100%;
}
</style>
