import { RouteRecordRaw } from 'vue-router'
import { defaultLocale } from '@/locales'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: `/${defaultLocale}`,
  },
  {
    path: '/service/order/qt-dns/success',
    redirect: `/${defaultLocale}/service/order/qt-dns/success`,
  },
  {
    path: '/service/order/qt-dns/canceled',
    redirect: `/${defaultLocale}/service/order/qt-dns/canceled`,
  },
  {
    path: '/:locale',
    component: () => import('@/components/Layout/index.vue'),
    children: [
      {
        path: '',
        redirect: `/${defaultLocale}/service/list`,
      },
      {
        path: 'about',
        component: () => import('@/views/About.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'payment/invoices',
        component: () => import('@/views/Payment/Invoices.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'payment/history',
        component: () => import('@/views/Payment/History.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'payment/card',
        component: () => import('@/views/Payment/Card/index.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'payment/card/current/success',
        component: () => import('@/views/Payment/Card/Current/Success.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'payment/card/new/success',
        component: () => import('@/views/Payment/Card/New/Success.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'payment/card/new/canceled',
        component: () => import('@/views/Payment/Card/New/Canceled.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'service/list',
        component: () => import('@/views/Service/List.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'service/order',
        component: () => import('@/views/Service/Order/index.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'service/order/domains',
        component: () => import('@/views/Service/Order/Domains.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'service/order/confirm',
        component: () => import('@/views/Service/Order/Confirm.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'service/order/qt-domain/success',
        component: () => import('@/views/Service/Order/QtDomain/Success.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'service/order/card',
        component: () => import('@/views/Service/Order/Card/index.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'service/order/qt-dns',
        component: () => import('@/views/Service/Order/QTDNS/index.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'service/order/qt-dns/confirm',
        component: () => import('@/views/Service/Order/QTDNS/Confirm.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'service/order/qt-dns/success',
        component: () =>
          import('@/views/Service/Order/QTDNS/Success/index.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'service/order/qt-dns/canceled',
        component: () =>
          import('@/views/Service/Order/QTDNS/Canceled/index.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'user/management',
        component: () => import('@/views/User/Management.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/thanks',
    component: () => import('@/views/Thanks.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/failed',
    component: () => import('@/views/Failed.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/signout',
    component: () => import('@/views/SignOut.vue'),
    meta: { requiresAuth: false },
  },

  {
    path: '/403',
    component: () => import('@/views/403.vue'),
  },
  { path: '/:pathMatch(.*)*', component: () => import('@/views/404.vue') },
]

export default routes
