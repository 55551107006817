import type { Status as MessageStatus } from '@/store/state'
import type { CustomerStatus } from '@/types'
import { defineAsyncComponent } from 'vue'
import urlJoin from 'url-join'

const getStatusIcon = (status: MessageStatus) => {
  switch (status) {
    case 'error': {
      return defineAsyncComponent(
        () => import('@/components/base/icons/mdi/MdiAlert.vue')
      )
    }

    case 'success': {
      return defineAsyncComponent(
        () => import('@/components/base/icons/mdi/MdiCheckCircle.vue')
      )
    }
  }
}

const getStatusColor = (status: MessageStatus) => {
  switch (status) {
    case 'error': {
      return 'text-red-500 '
    }

    case 'success': {
      return 'text-green-500'
    }
  }
}

const classStatus = (payload: CustomerStatus): string => {
  switch (payload) {
    case 'unapproved': {
      return 'text-yellow-800 bg-yellow-100'
    }

    case 'trial': {
      return 'text-blue-800 bg-blue-100'
    }

    case 'refused': {
      return 'text-red-800 bg-red-100'
    }
  }
}

const toCamelCase = (val: string): string => {
  const _str = val.charAt(0).toLowerCase() + val.slice(1)
  return _str.replace(/[-_](.)/g, (_, replacer) => {
    return replacer.toUpperCase()
  })
}

const toSnakeCase = (val: string): string =>
  toCamelCase(val).replace(
    /[A-Z]/g,
    (parameter) => '_' + parameter.charAt(0).toLowerCase()
  )

const key2SnakeCase = (obj: Record<string, unknown>) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    acc[toSnakeCase(key)] = value
    return acc
  }, {} as Record<string, unknown>)

const endsWith = (searchString: string) => (val: string): boolean =>
  val.endsWith(searchString)

const endWithSlash = endsWith('/')
const trailingSlash = (val: string): string =>
  endWithSlash(val) ? val.slice(0, -1) : val

const getRedirectUrl = (baseUrl: string, redirectTo: string): string =>
  urlJoin(baseUrl, `?redirect=${encodeURIComponent(redirectTo)}`)
export {
  getStatusIcon,
  getStatusColor,
  classStatus,
  key2SnakeCase,
  trailingSlash,
  getRedirectUrl,
}
