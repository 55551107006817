/* eslint-disable */
import Amplify from 'aws-amplify'
import { Plugin } from 'vue'

const awsmobile = {
  aws_cognito_region: process.env.VUE_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.VUE_APP_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.VUE_APP_AWS_USER_POOL_APP_CLIENT_ID,
}

const plugin: Plugin = {
  install: () => {
    Amplify.configure(awsmobile)
  },
}

export default plugin
