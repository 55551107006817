export const ja = {
  'Next month': '次の月',
  'Invoice Date': '請求日',
  'Total price without tax': '合計金額(税抜き)',
  'Service Name': 'サービス名',
  'Started date': '利用開始日',
  Terms: '期間',
  LimitDate: '期限',
  Amount: '数量',
  'Unit price': '単価',
  Tax: '税金',
  Currency: '通貨',
  'Payment status': '支払い状況',
  USD: '円',
  Completed: '完了',
  Failed: '失敗',
  'Apply to production': '本申し込み',
  'There is not available services now': '利用中のサービスはありません',
  Trial: 'トライアル中',
  Canceled: '解約済み',
  'Cancel subscription': '自動更新をやめる',
  'Do you want to cancel CUSTOMER PORTAL?':
    'カスタマーポータルの利用を停止しますか?',
  'You are going to cancel this service. We can not provide this service for you after cancelletion. Are you are?':
    '利用期間満了後、本サービスを利用できなくなります。なお、利用期限を過ぎると本サービスのデータは自動的に削除されます。本当によろしいですか?',
  'Removing all registered accounts from CUSTOMER PORTAL. You can not login after removing accounts. Are you sure?':
    'ご登録された全てのアカウントをカスタマーポータルより削除いたします。アカウントが削除された後はカスタマーポータルへのログインはできなくなります。カスタマーポータルの利用を停止しますか?',
  No: 'いいえ',
  Yes: 'はい',
  'Service List': 'サービス一覧',
  Invoice: '請求',
  'Payment History': '支払い履歴',
  'Incompleted the payment of this month': '今月のお支払いが未完了です',
  'Go to payment': 'お支払い画面へ',
  'Billing for the next month': '請求書(次の月)',
  Billing: '請求書',
  'No billings now': '現在請求書はありません',
  'Qualitia.co.,Ltd': '株式会社クオリティア',
  '3-11-10, Nihombashikayabacho, Chuo Ku, Tokyo To, 103-0025, Japan':
    '〒103-0025 東京都中央区日本橋茅場町３丁目１１−１０',
  Phone: '電話',
  '+81-3-5623-2530': '03-5623-2530',
  'Billing to': '請求先',
  Forcast: '未確定',
  'As point of': ' 時点',
  'Not updating subscription': '自動更新無し',
  Subscription: '自動更新',
  'User management': '組織管理',
  'Cancel CUSTOMER PORTAL': 'カスタマーポータルを解約する',
  'Buy services or options': 'サービス、オプションを購入する',
  'Thank you for using our service': '長い間ご利用ありがとうございました。',
  'We are waiting if you start to use our service in the future.':
    'またのご利用をお待ちしております。',
  'Buy now': '購入',
  Buy: '購入',
  'Select the payment method': 'お支払い方法の選択',
  Product: '商品',
  'Rate price': '単価',
  'Total amount': '金額',
  'Date is caliculated as UTC':
    '日付は全てUTC(協定世界時)で表示、計算されます。',
  Subtotal: '小計',
  'Sales tax': '消費税',
  'Total amount with tax': '合計(税込み)',
  'Please prepare an email address and a credit card. Moving to stripe. We are going to provide our service after payment is completed.':
    '※購入ボタンを押すとStripeの決済画面に移動します。クレジットカードの準備をお願いします。なお、お支払い完了後にサービスの提供が開始されます。',
  'QUALITIA DNS Monthly for {month}': 'QUALITIA DNS 月額 ({month}分)',
  'QUALITIA DNS Year from {start} to {end}':
    'QUALITIA DNS 年額 ({start} - {end})',
  '{address1} {address2} {city} {prefecture} {postalCode} {country}':
    '〒{postalCode} {prefecture}{city}{address1}{address2}',
  Update: '更新',
  'Note: The {zoneNum} zones is free': '備考：{zoneNum}ゾーンは無料です。',
  'QUALITIA DNS ZONES OPTION Monthly for {month}':
    'QUALITIA DNS ゾーンオプション 月額 ({month}分)',
  'QUALITIA DNS ZONES OPTION Year from {start} to {end}':
    'QUALITIA DNS ゾーンオプション 年額 ({start} - {end})',
  Payment: 'お支払い',
  'If you click purchase button, jumping to Stripe website.':
    '購入ボタンをクリックするとStripeの画面へジャンプします。そちらでカードの登録・変更を行うことができます。クレジットカードの準備をお願いします。',
  'You can register or change your credit card if you need.':
    'お支払い完了後にサービスの提供が開始されます。',
  'QUALITIA DNS ZONES OPTION': 'QUALITIA DNS ゾーンオプション',
  'The current zone amount': '現在のゾーン数',
  'The zone after changed': '変更後のゾーン数',
  'Payment method': '支払い方法',
  'Payment for now': '今回のお支払い',
  'Payment for the next subscription': '次回の定期支払い',
  Monthly: '月額',
  Yearly: '年額',
  'For {terms}': '{terms}分',
  'QUALITIA DNS ZONES OPTION product description':
    'QUALITIA DNSで扱えるゾーンの数を追加、削除します。QUALITIA DNSは無料で5ゾーン扱えます。それ以上のゾーンを扱い方はこちらよりオプションを購入してください。',
  'QUALITIA DNS product description':
    'DNSSECを標準対応したマネージドDNSサービスです。DNSSEC標準対応で改ざんを防ぎます。面倒な鍵の交換作業はクオリティアにおまかせ。メールをより強固にするためのSPF/DKIM/DMARCの設定をサポートします。日本とシンガポールの4つのサーバに分散されており、 高いSLAを実現。',
  'Service list': 'サービス一覧',
  Processing: '処理中',
  'There is no service which you can buy':
    '現在購入できるサービスはありません。',
  'The next zone amount': '次回のゾーン数',
  'Invalid parameter: more than {num}': '* {num}以上必要です。',
  'You can use {nextZoneNum} after {nextMonth}. Please remove {zoneNum} zones from QUALITIA DNS until {endOfMonth}.':
    '{nextMonth}より使用できるゾーン数は{nextZoneNum}になります。{endOfMonth}までにQUALITIA DNSより{zoneNum}ゾーン削除してください。',
  'You can use {currentZoneNum} now. Please remove {zoneNum} zones from QUALITIA DNS.':
    '現在使用できるゾーン数は{currentZoneNum}です。QUALITIA DNSより{zoneNum}ゾーン削除してください。',
  Updated: '更新しました',
  'Updated optional zone number': 'オプションのゾーン数を更新しました。',
  Agreements: '利用規約',
  'I agree': '同意します',
  'Do you agree the above agreements?': '以上に同意しますか?',
  'Privacy Policy': '個人情報保護ポリシー',
  'QUALITIA DNS': 'QUALITIA DNS',
  'QUALITIA DNSサービス利用約款': 'QUALITIA DNSサービス利用約款',
  反社会的勢力排除に関する包括的覚書: '反社会的勢力排除に関する包括的覚書',
  'Pay with the current card': '現在のカードで支払う',
  'Card Information': 'カード情報',
  Type: '種類',
  Number: '番号',
  Expire: '期限',
  Name: '名前',
  Email: 'メールアドレス',
  Pay: 'お支払い',
  'Pay by registering a new card.': '新しいカードを登録して支払う',
  'When you click the "Register" button, you will be redirected to the Stripe card registration screen.':
    '登録ボタンを押すとStripeのカード登録画面に遷移します。',
  Register: '登録',
  Required: '必須',
  'Oops! Page not found.': 'ページが見つかりません',
  'The page you ara looking for was not found.':
    'お探しのページが見つかりませんでした。',
  'Return to': '',
  home: 'ホームへ戻る',
  'This ': 'これには数分かかることがあります',
  'Payment failure': '決済失敗',
  'Please try again.': 'もう一度お試しください。',
  'Go to Application page': '申込画面へ',
  'Payment Succeeded': '決済成功',
  'Thank you for your application.':
    'お申し込みいただき、誠にありがとうございました。',
  'Back to Service List': 'サービス一覧へ戻る',
  'The price shown includes tax.': '※表示は税込み込み価格です。',
  'QUALITIA DNS Monthly Plan': 'QUALITIA DNS月額プラン',
  'Click on the "Apply" button and a confirmation screen will appear.':
    '申し込みボタンを押すと確認画面が表示されます。',
  Apply: 'お申し込み',
  'QUALITIA DNS Annual Plan': 'QUALITIA DNS年額プラン',
  'Welcome to CUSTOMER PORTAL': 'カスタマーポータルへようこそ',
  'This is an about page': 'これはAboutページです。',
  'Forbidden.': 'Forbidden.',
  'The page cannot be displayed because an access permission error has occurred.':
    'このページへのアクセス許可がなかったため、このページは表示できません。',
  'Try Again': 'リトライ',
  'This might take a few minutes.': 'これには数分かかることがあります',
  "Let's add some pictures.": 'なんか写真入れましょう',
  'Issue Date': '発行日',
  'Payment period': '支払い期日',
  Status: '状況',
  Service: '商品',
  'All dates are displayed and calculated in UTC (Coordinated Universal Time).':
    '日付は全てUTC(協定世界時)で表示、計算されます。',
  'Card registration completed.': 'カード登録完了',
  'Thank you for your application. Please wait for the payment to be completed.':
    'お申し込みいただき、誠にありがとうございました。お支払いが完了するまでお待ちください。',
  'Card Registration Failure': 'カード登録失敗',
  'Go to the Application page': '申請画面へ',
  'Your payment has been completed.': 'お支払いが完了しました',
  'Open main menu': 'メインメニューを開く',
  'Go to purchase': '購入画面へ進む',
  'CUSTOMER PORTAL': 'カスタマーポータル',
  'Sign Out': 'サインアウト',
  'You are signing out now. Are you sure?': 'サインアウトします。宜しいですか?',
  'Waiting for capture': '確定待ち',
  waiting: '確定待ち',
  canceled: 'キャンセル',
  completed: '支払い済み',
  failed: '未払い',
  Refused: '不承認',
  'Accepted your request': '申請を受け付けました。',
  'We will send you an email later': '後ほどemailにてご連絡差し上げます',
  'Apply domain transfer': 'ドメイン移管申請',
  'If you click apply button, start to process':
    '申請ボタンをクリックすると処理が開始されます。',
  'We will send you an email to tell what you need to do for domain transfer':
    'ドメイン移管の今後の流れに関してemailにてお伝えします。',
  'The payment will be fixed after domain transfer is completed':
    'ドメイン移管が完了した後、支払いが確定します。',
  'Back to Qualitia Domain': 'Qualitia Domainへ戻る',
  'General JP {domainName}': '汎用JP {domainName}',
}
