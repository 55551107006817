import Auth from '@aws-amplify/auth'

let _jwtToken: null | string = null

export async function refreshToken() {
  // return new Promise<string | null | undefined>((resolve, reject) => {
  //   Auth.currentUserInfo()
  //     .then(() => {
  //       Auth.currentAuthenticatedUser()
  //         .then((user) => {
  //           _jwtToken = user.signInUserSession.idToken.jwtToken
  //           resolve(_jwtToken)
  //         })
  //         .catch(() => reject())
  //     })
  //     .catch(() => reject)
  // })

  const res = await Auth.currentUserInfo()

  if (res == null) {
    return undefined
  }

  const user = await Auth.currentAuthenticatedUser()

  if (user == null) {
    return undefined
  }

  _jwtToken = user.signInUserSession.idToken.jwtToken

  return _jwtToken
}

export function getToken() {
  return _jwtToken
}
