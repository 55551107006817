export const en = {
  'QUALITIA DNS Monthly for {month}': 'QUALITIA DNS Monthly for {month}',
  'QUALITIA DNS Year from {start} to {end}':
    'QUALITIA DNS Year from {start} to {end}',
  'QUALITIA DNS ZONES OPTION Monthly for {month}':
    'QUALITIA DNS ZONES OPTION Monthly for {month}',
  'QUALITIA DNS ZONES OPTION Year from {start} to {end}':
    'QUALITIA DNS ZONES OPTION Year from {start} to {end}',
  '{address1} {address2} {city} {prefecture} {postalCode} {country}':
    '{address1} {address2} {city} {prefecture} {postalCode} {country}',
  'For {terms}': 'For {terms}',
  'Note: The {zoneNum} zones is free': 'Note: The {zoneNum} zones is free',
  'Invalid parameter: more than {num}': 'Invalid parameter: more than {num}',
  'Please remove {num} zones from QUALITIA DNS':
    'Please remove {num} zones from QUALITIA DNS',
  'You can use {nextZoneNum} after {nextMonth}. Please remove {zoneNum} zones from QUALITIA DNS until {endOfMonth}.':
    'You can use {nextZoneNum} after {nextMonth}. Please remove {zoneNum} zones from QUALITIA DNS until {endOfMonth}.',
  'You can use {currentZoneNum} now. Please remove {zoneNum} zones from QUALITIA DNS.':
    'You can use {currentZoneNum} now. Please remove {zoneNum} zones from QUALITIA DNS.',
  waiting: 'Waiting',
  canceled: 'Canceled',
  completed: 'Completed',
  failed: 'Failed',
}
