import { useI18n } from 'vue-i18n'
import { en } from '@/locales/en'
import { ja } from '@/locales/ja'

export const defaultLocale = 'ja'

export const useTranslation = () => {
  // console.log('hohoh')
  const { t, d, n } = useI18n({
    messages: { en, ja },
    numberFormats: {
      en: {
        currency: {
          style: 'currency',
          currency: 'JPY',
          currencyDisplay: 'symbol', //TODO: We want to use yen instead of symbol
        },
      },
      ja: {
        currency: {
          style: 'currency',
          currency: 'JPY',
          currencyDisplay: 'name',
        },
      },
    },
    datetimeFormats: {
      en: {
        m: {
          month: 'long',
          timeZone: 'UTC',
        },
        terms: {
          year: 'numeric',
          month: 'short',
          timeZone: 'UTC',
        },
        ymd: {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          timeZone: 'UTC',
        },
        ymdhms: {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZone: 'UTC',
        },
      },
      ja: {
        m: {
          month: 'long',
          timeZone: 'UTC',
        },
        terms: {
          year: 'numeric',
          month: 'short',
          timeZone: 'UTC',
        },
        ymd: {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          timeZone: 'UTC',
        },
        ymdhms: {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZone: 'UTC',
        },
        // If you want to expand date format please adding below.
        // short: {
        //   year: 'numeric',
        //   month: 'short',
        //   day: 'numeric',
        // },
        // long: {
        //   year: 'numeric',
        //   month: 'long',
        //   day: 'numeric',
        //   weekday: 'long',
        //   hour: 'numeric',
        //   minute: 'numeric',
        //   hour12: false,
        //   timeZone: 'UTC',
        //   timeZoneName: 'long',
        // },
      },
    },
  })
  // console.log('dd')
  return { t, d, n }
}

// export const tMonth = (month: string) => {
//   //i18nとかのライブラリ使うと、勝手にtimezoneを配慮して変換する可能性があるので、
//   //とりあえず、自前で変換することにする。ただし、言語対応が増えた場合は、別途配慮する。
// }
