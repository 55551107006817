import { Plugin } from 'vue'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

const plugin: Plugin = {
  install: (app) => {
    Sentry.init({
      // dsn: process.env.VUE_APP_DSN,
      dsn: '',
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    })

    app.config.errorHandler = (err) => {
      Sentry.captureException(err)
    }
  },
}

export default plugin
